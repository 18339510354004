import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
const StoreManager = () => {
    const [storeExists, setStoreExists] = useState(null);
    const navigate = useNavigate()
    useEffect(() => {
        const fetchStore = async () => {
            try {
                const response = await axios.post(
                    `https://markaz-api-ee158599a77e.herokuapp.com/stores/getStore`,
                    //   {
                    //     vendorId: 'your-vendor-id-here', // Replace with the actual vendor ID
                    //   },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer jCXumGJZn3333Sfsr1HiZ06Czg==2`, // Replace with your secret key
                        },
                    }
                );
                if (response.data && response.data.store) {
                    setStoreExists(true);
                } else {
                    setStoreExists(false);
                }
            } catch (error) {
                console.error('Error fetching store:', error);
                setStoreExists(false);
            }
        };

        fetchStore();
    }, []);

    //   if (storeExists === null) {
    //     return <div>Loading...</div>;
    //   }

    return storeExists ? (
        <div className="p-4 bg-gray-100 h-screen">
            <div className="bg-white rounded-lg shadow-md p-4 mb-4">
                <div className="flex justify-between items-center mb-4">
                    <div className="flex items-center justify-center ">
                        <img
                            src="https://img.icons8.com/ios/50/000000/no-image.png"
                            alt="Store Image"
                            className="w-16 h-16 bg-gray-200 rounded-md"
                        />
                        <div className="ml-4">
                            <h2 className="text-lg font-semibold">slash</h2>
                        </div>
                    </div>
                    <div className="flex space-x-2">
                        <button className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md">Edit Store</button>
                        <button className="px-4 py-2 bg-green-500 text-white rounded-md">Approved</button>
                    </div>
                </div>
                <div className="text-red-600 mb-4">
                    <p>❌ Store dont have Image. Please upload store image</p>
                    <p>❌ delivery section incomplete. Please complete Delivery Section</p>
                    <p>❌ Store timing is missing. Please complete the time section</p>
                    <p>❌ Store Location missing</p>
                </div>
                <div className="mb-4">
                    <h3 className="text-lg font-semibold mb-2">Orders</h3>
                    <div className="grid grid-cols-3 gap-4">
                        <div className="bg-green-500 text-white p-4 rounded-md text-center">
                            <p>Today's Order</p>
                            <p className="text-2xl">0</p>
                        </div>
                        <div className="bg-yellow-500 text-white p-4 rounded-md text-center">
                            <p>Yesterday Order</p>
                            <p className="text-2xl">0</p>
                        </div>
                        <div className="bg-red-500 text-white p-4 rounded-md text-center">
                            <p>Total Order</p>
                            <p className="text-2xl">0</p>
                        </div>
                    </div>
                    <button className="mt-4 px-6 py-3 bg-black text-white rounded-md w-full">View All Orders</button>
                </div>
                <div className="text-center mt-4">
                    <p className="mb-4">Click below to add your First Product and start Selling all over Kuwait</p>
                    <button className="px-6 py-3 bg-black text-white rounded-md">Add Product</button>
                </div>
            </div>
        </div>
    ) : (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <div className="text-center flex flex-col items-center ">
                <img
                    src="https://img.icons8.com/color/96/000000/shop.png"
                    alt="Store Icon"
                    className="mb-4"
                />
                <h2 className="text-xl font-semibold mb-4">Click Below to create your Store</h2>
                <button className="px-6 py-3 bg-black text-white rounded-md" onClick={() =>navigate("/create-store")}>Create Store</button>
            </div>
        </div>
    );
};

export default StoreManager;
