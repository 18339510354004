import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; 
import Swal from "sweetalert2";

const SignInScreen = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password, rememberMe } = formData;

    if (!validateEmail(email)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Email",
        text: "Please enter a valid email address.",
      });
      return;
    }
    if (!password) {
      Swal.fire({
        icon: "error",
        title: "Empty Password",
        text: "Please enter your password.",
      });
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        "https://markaz-api-ee158599a77e.herokuapp.com/vendors/signin",
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer jCXumGJZn3333Sfsr1HiZ06Czg==2", 
          },
        }
      );

      // Success: Navigate or show success message
      Swal.fire({
        icon: "success",
        title: "Sign-In Successful",
        text: "Redirecting...",
        timer: 1500,
        showConfirmButton: false,
      }).then(() => {
        
        if (rememberMe) {
          localStorage.setItem("userEmail", email);
        }

        navigate("/dashboard");
      });
    } catch (error) {
      console.error("Error during sign-in:", error);
      Swal.fire({
        icon: "error",
        title: "Sign-In Failed",
        text: error.response?.data?.message || "Something went wrong. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-bold text-center mb-6">Sign In</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded mt-1 focus:outline-none focus:border-blue-500"
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded mt-1 focus:outline-none focus:border-blue-500"
              placeholder="Enter your password"
              required
            />
          </div>
          <div className="flex justify-between items-center mb-4">
            <a
              onClick={() => navigate(`/forgot-password?email=${formData.email}`)}
              className="text-sm text-blue-500 hover:underline cursor-pointer"
            >
              Forgot password?
            </a>
          </div>
          <div className="flex items-center mb-4">
            <input
              type="checkbox"
              id="remember"
              name="rememberMe"
              checked={formData.rememberMe}
              onChange={handleChange}
              className="mr-2"
            />
            <label htmlFor="remember" className="text-gray-700">
              Remember Me
            </label>
          </div>
          <button
            type="submit"
            className="w-full bg-black text-white py-2 rounded hover:bg-gray-800"
            disabled={loading}
          >
            {loading ? "Signing In..." : "Sign In"}
          </button>
        </form>
        <p
          
          className="text-center mt-4 text-blue-600 cursor-pointer"
        >
          Create Account
        </p>
      </div>
    </div>
  );
};

export default SignInScreen;
