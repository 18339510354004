import { useState } from "react";

const StoreInfo = ({ onNext }) => {
  const [storeInfo, setStoreInfo] = useState({
    nameEn: "",
    nameAr: "",
    category: "",
    whatsapp: "+966 ", 
    instagram: "",
    website: "www.",
    storeType: "",
    image: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStoreInfo({ ...storeInfo, [name]: value });
  };

  const handleImageChange = (e) => {
    setStoreInfo({ ...storeInfo, image: e.target.files[0] });
  };

  return (
    <form className="space-y-6">
      {/* Store Name (English) */}
      <div>
        <label className="block text-gray-700 font-medium">Store Name (English)</label>
        <input
          type="text"
          name="nameEn"
          value={storeInfo.nameEn}
          onChange={handleChange}
          placeholder="apple"
          className="mt-1 w-full border px-3 py-2 rounded-md shadow-sm"
          required
        />
      </div>

      {/* Store Name (Arabic) */}
      <div>
        <label className="block text-gray-700 font-medium">Store Name (Arabic)</label>
        <input
          type="text"
          name="nameAr"
          value={storeInfo.nameAr}
          onChange={handleChange}
          placeholder="أبل"
          className="mt-1 w-full border px-3 py-2 rounded-md shadow-sm"
          required
        />
      </div>

      {/* Store Category (Select) */}
      <div>
        <label className="block text-gray-700 font-medium">Store Category</label>
        <select
          name="category"
          value={storeInfo.category}
          onChange={handleChange}
          className="mt-1 w-full border px-3 py-2 rounded-md shadow-sm"
          required
        >
          <option value="">Select Category</option>
          <option value="sports">Sports and Fitness</option>
          <option value="electronics">Electronics</option>
          <option value="fashion">Fashion</option>
        </select>
      </div>

      {/* WhatsApp Mobile */}
      <div>
        <label className="block text-gray-700 font-medium">WhatsApp Mobile</label>
        <input
          type="text"
          name="whatsapp"
          value={storeInfo.whatsapp}
          onChange={handleChange}
          placeholder="+966 51160282"
          className="mt-1 w-full border px-3 py-2 rounded-md shadow-sm"
          required
        />
      </div>

      {/* Store Instagram */}
      <div>
        <label className="block text-gray-700 font-medium">Store Instagram</label>
        <input
          type="url"
          name="instagram"
          value={storeInfo.instagram}
          onChange={handleChange}
          placeholder="www.instagram.com/insta"
          className="mt-1 w-full border px-3 py-2 rounded-md shadow-sm"
        />
      </div>

      {/* Store Website */}
      <div>
        <label className="block text-gray-700 font-medium">Store Website</label>
        <input
          type="url"
          name="website"
          value={storeInfo.website}
          onChange={handleChange}
          placeholder="www."
          className="mt-1 w-full border px-3 py-2 rounded-md shadow-sm"
        />
      </div>

      {/* Store Type (Radio Buttons) */}
      <div className="mt-4">
        <label className="block text-gray-700 font-medium">Store Type</label>
        <div className="flex items-center space-x-4 mt-2">
          <label className="inline-flex items-center">
            <input
              type="radio"
              name="storeType"
              value="physical"
              onChange={handleChange}
              className="form-radio h-4 w-4 text-green-600"
            />
            <span className="ml-2">Physical</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              name="storeType"
              value="online"
              onChange={handleChange}
              className="form-radio h-4 w-4 text-green-600"
            />
            <span className="ml-2">Online</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              name="storeType"
              value="home"
              onChange={handleChange}
              className="form-radio h-4 w-4 text-green-600"
            />
            <span className="ml-2">Home</span>
          </label>
        </div>
      </div>

      {/* Store Image */}
      <div className="mt-4">
        <label className="block text-gray-700 font-medium">Store Image</label>
        <input
          type="file"
          onChange={handleImageChange}
          className="mt-1 w-full border px-3 py-2 rounded-md shadow-sm"
        />
      </div>

      {/* Save and Next Button */}
      <div className="mt-6">
        <button
          type="button"
          onClick={() => onNext(storeInfo)}
          className="w-full bg-black text-white py-3 rounded-md shadow hover:bg-gray-800 transition"
        >
          Save and Next
        </button>
      </div>
    </form>
  );
};

export default StoreInfo;
