// src/pages/Home.js
import React, { useContext } from "react";
import { LanguageContext } from "../context/createContext";

const Home = () => {
  const { language } = useContext(LanguageContext);

  const translations = {
    en: {
      title: "Download Our Application",
      description: "Get the Markazz app from the Play Store or App Store.",
      googlePlayLink: "https://play.google.com/store/apps/details?id=com.markaz.buyer_app",
      appStoreLink: "https://apps.apple.com/app/markazz-kuwait-shopping-online/id6667114733",
    },
    ar: {
      title: "قم بتنزيل تطبيقنا",
      description: "احصل على تطبيق Markazz من متجر Play أو App Store.",
      googlePlayLink: "https://play.google.com/store/apps/details?id=com.markaz.buyer_app",
      appStoreLink: "https://apps.apple.com/app/markazz-kuwait-shopping-online/id6667114733",
    },
  };

  const t = translations[language];

  return (
    <div className="bg-light-blue min-h-screen flex flex-col items-center justify-center">
      <main className="flex flex-col items-center justify-center flex-1 text-center">
        <h1 className="text-4xl font-extrabold mb-6 text-gray-800 font-rubik">
          {t.title}
        </h1>
        <p className="text-lg text-gray-600 mb-10">
          {t.description}
        </p>
        <div className="flex space-x-4">
          <a
            href={t.googlePlayLink}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-gray-900 text-white px-4 py-2 rounded-lg hover:bg-gray-800"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
              alt="Google Play Store"
              className="h-12"
            />
          </a>
          <a
            href={t.appStoreLink}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-gray-900 text-white px-4 py-2 rounded-lg hover:bg-gray-800"
          >
            <img
              src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
              alt="App Store"
              className="h-12"
            />
          </a>
        </div>
      </main>
    </div>
  );
};

export default Home;
