import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const ContinueOptionScreen = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = formData;

    // Basic email validation
    if (!validateEmail(email)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Email",
        text: "Please enter a valid email address.",
      });
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        "https://markaz-api-ee158599a77e.herokuapp.com/vendors/isEmailExist",
        { email },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer jCXumGJZn3333Sfsr1HiZ06Czg==2", 
          },
        }
      );

      const { status, message } = response.data;

  
      if (status === 1) {
        Swal.fire({
          icon: "success",
          title: "Email Exists",
          text: "Navigating to sign-in screen...",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {
          navigate("/"); 
        });
      } else if (status === 2) {
        Swal.fire({
          icon: "info",
          title: "Email Not Found",
          text: "Navigating to registration screen...",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {
          navigate("/signin");
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: message || "Something went wrong. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error checking email:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an issue checking the email. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-bold text-center mb-4">
          Welcome to <span className="text-blue-600">Markaz Manager</span>
        </h2>
        <p className="text-center mb-6">
          Join <span className="font-bold">Markaz</span> Marketplace and start selling today all over Kuwait.
        </p>
        <p className="text-center text-gray-500 mb-8">Sign up or Log in</p>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded mt-1 focus:outline-none focus:border-blue-500"
              placeholder="Enter your email"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-black text-white py-2 rounded hover:bg-gray-800"
            disabled={loading}
          >
            {loading ? "Checking..." : "Continue"}
          </button>
        </form>
        <p className="text-center mt-4 text-blue-600 cursor-pointer">عربي</p>
      </div>
    </div>
  );
};

export default ContinueOptionScreen;
