// src/Component/Sidebar/Sidebar.js
import React, { useContext } from "react";
import { LanguageContext } from "../../context/createContext";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const { toggleLanguage } = useContext(LanguageContext);

  return (
    <div
      className={`fixed top-0 right-0 w-64 h-full flex flex-col items-start justify-end bg-gray-200 p-4 transition-transform transform ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <button
        onClick={toggleSidebar}
        className="absolute top-4 left-4 text-2xl font-bold"
      >
        &times;
      </button>
      <h2 className="text-xl font-bold mb-4">Select Language</h2>
      <button
        onClick={() => toggleLanguage("en")}
        className="block mb-2 w-full text-left p-2 bg-white hover:bg-gray-100 rounded"
      >
        English
      </button>
      <button
        onClick={() => toggleLanguage("ar")}
        className="block w-full text-left p-2 bg-white hover:bg-gray-100 rounded"
      >
        Arabic
      </button>
    </div>
  );
};

export default Sidebar;
