import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./Component/Header/Header";
import MarkazComponent from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import { LanguageProvider } from "./context/createContext";
import Sidebar from "./Component/Sidebar/Sidebar"; // Sidebar for language selection
import "./App.css";
import TermConditions from "./pages/Term&Conditions";
import ContactUs from "./pages/ContactUs";
import LoginScreen from "./pages/Login";
import SignInScreen from "./pages/SignIn";
import Dashboard from "./pages/dashboard";
import CreateStoreForm from "./pages/createStoreForm/index"
import 'leaflet/dist/leaflet.css';

const App = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <LanguageProvider>
      <div className="relative flex">
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div className="flex-1">
          <Header toggleSidebar={toggleSidebar} />
          <Routes>
            <Route path="/" element={<MarkazComponent />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/privacy-policy" element={<TermConditions />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/signin" element={<SignInScreen />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/create-store" element={<CreateStoreForm />} />
          </Routes>
        </div>
      </div>
    </LanguageProvider>
  );
};

export default App;
