// src/Component/Header/Header.js
import React, { useContext } from "react";
import logo from "../../assets/Images/markazzlogo.png";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../../context/createContext";

const Header = ({ toggleSidebar }) => {
  const navigate = useNavigate();

  const { language, toggleLanguage } = useContext(LanguageContext);

  const translations = {
    en: {
      home: "Home",
      aboutUs: "About Us",
      termandcondition: " Terms and Conditions",
      ContactUs: "Contact Us",
      toggleSidebar: "☰",
      english: "English",
      arabic: "العربية",
    },
    ar: {
      home: "الصفحة الرئيسية",
      aboutUs: "معلومات عنا",
      termandcondition: "الشروط والاحكام",
      ContactUs: "اتصل بنا",
      toggleSidebar: "☰",
      english: "English",
      arabic: "العربية",
    },
  };

  const t = translations[language];
  const handleLanguageChange = (lang) => {
    language === "en" ? toggleLanguage("ar") : toggleLanguage("en");
  };

  return (
    <header className="w-full bg-gray-100 py-3 shadow-xl">
      <div className="container mx-auto w-[90%] flex justify-between items-center">
        <img src={logo} alt="Markazz Logo" className="h-12" />
        <nav className="flex items-center space-x-6">
          <ul className="flex space-x-6">
            <li className="cursor-pointer" onClick={() => navigate("/")}>
              <a className="text-gray-700 font-semibold">{t.home}</a>
            </li>
            <li
              className="cursor-pointer"
              onClick={() => navigate("/about-us")}
            >
              <a className="text-gray-700 font-semibold">{t.aboutUs}</a>
            </li>
            <li
              className="cursor-pointer"
              onClick={() => navigate("/privacy-policy")}
            >
              <a className="text-gray-700 font-semibold">
                {t.termandcondition}
              </a>
            </li>
            <li
              className="cursor-pointer"
              onClick={() => navigate("/contact-us")}
            >
              <a className="text-gray-700 font-semibold">{t.ContactUs}</a>
            </li>
          </ul>
          <button
            onClick={() => handleLanguageChange()}
            className="font-semibold bg-gray-900 py-1 px-2 rounded text-white"
          >
            {language === "en" ? "العربية" : " English"}
          </button>
          {/* <div>
            <select
              value={language}
              onChange={(e) => toggleLanguage(e.target.value)}
              className="bg-white border border-gray-300 text-gray-700 py-2 px-6 pr-8 pl-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option value="en">{t.english}</option>
              <option value="ar">{t.arabic}</option>
            </select>
          </div> */}
        </nav>
      </div>
    </header>
  );
};

export default Header;
