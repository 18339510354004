import React, { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
const DaySchedule = ({ day }) => {
  const [times, setTimes] = useState([{ start: '', end: '' }]);
  const [isOpen, setIsOpen] = useState(true);

  const addTimeSlot = () => {
    setTimes([...times, { start: '', end: '' }]);
  };

  const removeTimeSlot = (index) => {
    const updatedTimes = times.filter((_, i) => i !== index);
    setTimes(updatedTimes);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="my-4">
      <div className="flex items-center justify-between">
        <span className="font-medium">{day}</span>
        <div className="flex items-center space-x-2">
          <span>{isOpen ? 'OPEN' : 'CLOSE'}</span>
          <Switch checked={isOpen} onChange={handleToggle} />
        </div>
      </div>
      {isOpen && (
        <div className="flex flex-col mt-2 space-y-2">
          {times.map((time, index) => (
            <div key={index} className="flex justify-between items-center space-x-2">
              <div>
                <input
                  type="time"
                  value={time.start}
                  onChange={(e) => {
                    const newTimes = [...times];
                    newTimes[index].start = e.target.value;
                    setTimes(newTimes);
                  }}
                  className="border border-gray-300 p-2 rounded-md w-full sm:w-auto"
                />
                <span><ArrowRightIcon/></span>
                <input
                  type="time"
                  value={time.end}
                  onChange={(e) => {
                    const newTimes = [...times];
                    newTimes[index].end = e.target.value;
                    setTimes(newTimes);
                  }}
                  className="border border-gray-300 p-2 rounded-md w-full sm:w-auto"
                />
              </div>

              {index === 0 ? (
                <button onClick={addTimeSlot} className="ml-2">
                  <AddCircleIcon className="text-black" />
                </button>
              ) : (
                <button onClick={() => removeTimeSlot(index)} className="ml-2">
                  <DeleteIcon className="text-red-500" />
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const ScheduleForm = () => {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

  return (
    <div className="p-4  mx-auto">
      {days.map((day, index) => (
        <DaySchedule key={index} day={day} />
      ))}
      <button className="bg-black text-white p-3 rounded-md w-full mt-4">
        Save and Next
      </button>
      <button className="bg-white text-black border border-black p-3 rounded-md w-full mt-2">
        Back
      </button>
    </div>
  );
};

export default ScheduleForm;
