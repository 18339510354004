import { useState } from "react";
import StoreInfo from './storeInfo';
import Delivery from './deliveryInfo';
import Timing from './timingInfo';
import Location from './locationinfo';

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState({
    storeInfo: {},
    delivery: {},
    timing: {},
    location: {}
  });

  const handleNext = (newData, step) => {
    setFormData({ ...formData, [step]: newData });
    setActiveTab(activeTab + 1);
  };

  return (
    <div className="w-full max-w-4xl mx-auto pt-4">
      {/* Tabs navigation */}
      <div className="flex justify-between border-b-2 border-gray-200 mb-4">
        {['Store Info', 'Delivery', 'Timing', 'Location'].map((tab, idx) => (
          <button
            key={idx}
            onClick={() => setActiveTab(idx)}
            className={`px-4 py-2 ${activeTab === idx ? 'border-b-2 border-green-600' : ''}`}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Tabs content */}
      <div>
        {activeTab === 0 && <StoreInfo onNext={(data) => handleNext(data, 'storeInfo')} />}
        {activeTab === 1 && <Delivery onNext={(data) => handleNext(data, 'delivery')} />}
        {activeTab === 2 && <Timing onNext={(data) => handleNext(data, 'timing')} />}
        {/* {activeTab === 3 && <Location onNext={(data) => handleNext(data, 'location')} />} */}
      </div>
    </div>
  );
};

export default Tabs;
