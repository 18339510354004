import { useState } from "react";

const DeliveryInfo = ({ onNext, onBack }) => {
    const [deliveryInfo, setDeliveryInfo] = useState({
        fulfillmentChannel: "merchant", // Default: "I will deliver the item myself"
        deliveryTime: "1 Day",
        deliveryCharges: 1000, // Default KWD value
        freeDelivery: false,
        minimumOrder: false,
    });

    // Handle input changes
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setDeliveryInfo({
            ...deliveryInfo,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    // Handle delivery charges increment/decrement
    const handleChargesChange = (type) => {
        setDeliveryInfo((prev) => ({
            ...prev,
            deliveryCharges: type === "increment" ? prev.deliveryCharges + 1 : prev.deliveryCharges - 1,
        }));
    };

    return (
        <form className="space-y-6">
            {/* Fulfillment Channel */}
            <div>
                <label className="block text-gray-700 font-medium">Fulfillment Channel</label>
                <div className="mt-2 space-y-2">
                    <label className="flex items-center space-x-3">
                        <input
                            type="radio"
                            name="fulfillmentChannel"
                            value="merchant"
                            checked={deliveryInfo.fulfillmentChannel === "merchant"}
                            onChange={handleChange}
                            className="form-radio h-4 w-4 text-green-600"
                        />
                        <span>I will deliver the item myself (Merchant fulfilled)</span>
                    </label>
                    <label className="flex items-center space-x-3">
                        <input
                            type="radio"
                            name="fulfillmentChannel"
                            value="markaz"
                            checked={deliveryInfo.fulfillmentChannel === "markaz"}
                            onChange={handleChange}
                            className="form-radio h-4 w-4 text-green-600"
                        />
                        <span>
                            Markaz will pick the item from your store and will deliver to the customer (Fulfilled by Markaz)
                        </span>
                    </label>
                </div>
            </div>

            {/* Select Delivery Hour */}
            <div>
                <label className="block text-gray-700 font-medium">Select delivery hour</label>
                <select
                    name="deliveryTime"
                    value={deliveryInfo.deliveryTime}
                    onChange={handleChange}
                    className="mt-1 w-full border px-3 py-2 rounded-md shadow-sm"
                    required
                >
                    <option value="1 Day">1 Day</option>
                    <option value="2 Days">2 Days</option>
                    <option value="3 Days">3 Days</option>
                </select>
            </div>

            {/* Delivery Charges */}
            <div>
                <label className="block text-gray-700 font-medium">Delivery Charges</label>
                <div className="mt-2 flex items-center space-x-4">
                    <button
                        type="button"
                        onClick={() => handleChargesChange("decrement")}
                        className="bg-gray-200 px-4 py-2 rounded-md"
                    >
                        -
                    </button>
                    <input
                        type="number"
                        name="deliveryCharges"
                        value={deliveryInfo.deliveryCharges}
                        onChange={handleChange}
                        className="w-20 text-center border px-3 py-2 rounded-md shadow-sm"
                    />
                    <button
                        type="button"
                        onClick={() => handleChargesChange("increment")}
                        className="bg-gray-200 px-4 py-2 rounded-md"
                    >
                        +
                    </button>
                    <span className="text-gray-700 font-medium">KWD</span>
                </div>
            </div>

            {/* Free Delivery */}
            <div>
                <label className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        name="freeDelivery"
                        checked={deliveryInfo.freeDelivery}
                        onChange={handleChange}
                        className="form-checkbox h-5 w-5 text-green-600"
                    />
                    <span className="text-gray-700">Free Delivery</span>
                </label>
            </div>

            {/* Free Delivery on Minimum Order */}
            <label class="flex justify-between items-center mb-5 cursor-pointer">
                <div>
                    <span class=" text-md font-medium text-gray-900 dark:text-gray-300">Free Delivery on Minimum Order</span>
                </div>
                <div>
                    <input type="checkbox" value="" class="sr-only peer" />
                    <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </div>
            </label>

            <div>

            </div>

            {/* Save and Next Button */}

            <button
                type="button"
                onClick={() => onNext(deliveryInfo)}
                className="bg-black text-white py-3 px-6 rounded-md shadow hover:bg-gray-800 transition w-full"
            >
                Save and Next
            </button>

            <button
                type="button"
                onClick={onBack}
                className="bg-gray-200 text-gray-700 py-3 px-6 rounded-md shadow hover:bg-gray-300"
            >
                Back
            </button>
        </form>
    );
};

export default DeliveryInfo;
