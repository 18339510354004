import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    email: "",
    content: "",
    name: "",
    mobileNumber: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://markaz-api-ee158599a77e.herokuapp.com/users/sendsupportemail",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer jCXumGJZn3333Sfsr1HiZ06Czg==2",
          },
        }
      );
      console.log("Response:", response.data);
      setFormData({
        email: "",
        content: "",
        name: "",
        mobileNumber: "",
      });
      Swal.fire({
        position: "top-end",
        icon: "success",
        title:  response.data.message,
        showConfirmButton: false,
        timer: 1500
      });
      // You can show a success message here
    } catch (error) {
      console.error("Error sending email:", error);
      setFormData({
        email: "",
        content: "",
        name: "",
        mobileNumber: "",
      });
      // You can show an error message here
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen mt-4 mb-4">
      <div className="flex flex-col-reverse md:flex-row items-center w-full max-w-4xl p-8 space-y-6 md:space-y-0 md:space-x-16 bg-white rounded-lg shadow-md">
        {/* Left Side: Information Paragraph */}
        <div className="w-full md:w-1/2">
          <h2 className="text-2xl font-bold text-gray-800">
            Having Issues with Markazz App?
          </h2>
          <p className="mt-4 text-gray-600">
            If you're encountering any issues with the Markazz App, whether it's
            related to e-commerce functionalities or marketing tools, we're here
            to help. Please reach out to us by filling out the form on the
            right. Our support team will get back to you as soon as possible to
            resolve your concerns.
          </p>
        </div>

        {/* Right Side: Contact Form */}
        <div className="w-full md:w-1/2">
          <h2 className="text-2xl font-bold text-center text-gray-800">
            Contact Us
          </h2>
          <form className="space-y-4 mt-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label
                htmlFor="mobileNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Mobile Number
              </label>
              <input
                type="tel"
                id="mobileNumber"
                name="mobileNumber"
                className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your Mobile Number"
                value={formData.mobileNumber}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label
                htmlFor="content"
                className="block text-sm font-medium text-gray-700"
              >
                Message
              </label>
              <textarea
                id="content"
                name="content"
                rows="4"
                className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your Message"
                value={formData.content}
                onChange={handleChange}
                required
              ></textarea>
            </div>

            <div>
              <button
                type="submit"
                className="w-full px-4 py-2 font-medium text-white bg-black rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
