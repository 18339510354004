// src/pages/AboutUs.js
import React, { useContext } from "react";
import { LanguageContext } from "../context/createContext";
import Image from "../assets/Images/ecommerce.jpg";

const AboutUs = () => {
  const { language } = useContext(LanguageContext);

  const translations = {
    en: {
      title: "About Us",
      description:
        "Welcome to Markazz, a premier marketplace based in Kuwait. We specialize in connecting customers with a wide range of products and services, providing a seamless shopping experience in the heart of the Middle East.",
      businessName: "Business Name:",
      businessOccupation: "Business Occupation:",
      businessLocation: "Business Location:",
      ecomSectionTitle: "Empowering Your Shopping Experience",
      ecomSectionDescription:
        "At Markazz, we understand the power of e-commerce and the pivotal role it plays in today's market. Our platform is designed to bring together a diverse range of sellers and buyers, fostering a vibrant and competitive marketplace. With a focus on innovation and customer satisfaction, we strive to offer the best products at competitive prices, all within an intuitive and user-friendly environment.",
      mission:
        "As we continue to grow, our mission remains the same: to be your go-to destination for all your shopping needs, right here in Kuwait. Whether you're looking for the latest gadgets, fashion, or home essentials, Markazz has got you covered.",
      BussinesName: "Markazz",
      BussinesOccupation: "Marketplace",
      BussinesLocation: "Kuwait",
    },
    ar: {
      title: "معلومات عنا",
      description:
        "مرحبًا بكم في مركز ، سوق متميز مقره الكويت. نحن متخصصون في ربط العملاء بمجموعة واسعة من المنتجات والخدمات ، وتقديم تجربة تسوق سلسة في قلب الشرق الأوسط.",
      businessName: "اسم العمل:",
      businessOccupation: "مهنة العمل:",
      businessLocation: "موقع العمل:",
      ecomSectionTitle: "تمكين تجربة التسوق الخاصة بك",
      ecomSectionDescription:
        "في Markazz ، نفهم قوة التجارة الإلكترونية والدور المحوري الذي تلعبه في سوق اليوم. تم تصميم منصتنا لجمع مجموعة متنوعة من البائعين والمشترين معًا ، مما يعزز سوقًا نابضًا بالحياة وتنافسية. مع التركيز على الابتكار ورضا العملاء ، نسعى جاهدين لتقديم أفضل المنتجات بأسعار تنافسية ، وكل ذلك في بيئة بديهية وسهلة الاستخدام.",
      mission:
        "مع استمرارنا في النمو ، تظل مهمتنا كما هي: أن نكون وجهتك المفضلة لجميع احتياجات التسوق الخاصة بك ، هنا في الكويت. سواء كنت تبحث عن أحدث الأدوات أو الموضة أو أساسيات المنزل ، فإن Markazz تغطيك.",
      BussinesName: "مركز",
      BussinesOccupation: "السوق",
      BussinesLocation: "الكويت",
    },
  };

  const t = translations[language];
  console.log(language, "language");

  return (
    <div className="bg-white min-h-screen p-6">
      <div className="container mx-auto w-[90%]">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-extrabold text-gray-800 mb-4">
            {t.title}
          </h1>
          <p className="text-lg text-gray-600 mb-8">{t.description}</p>
          <div className="space-y-2">
            <p>
              <strong>{t.businessName}</strong> {t.BussinesName}
            </p>
            <p>
              <strong>{t.businessOccupation}</strong> {t.BussinesOccupation}
            </p>
            <p>
              <strong>{t.businessLocation}</strong> {t.BussinesLocation}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex flex-col justify-center">
            <h2
              className={`text-2xl ${
                language === "ar" ? "text-right" : ""
              } font-semibold text-gray-800 mb-4`}
            >
              {t.ecomSectionTitle}
            </h2>
            <p className={`text-lg ${language === "ar" ? "text-right" : ""} text-gray-600 mb-6`}>
              {t.ecomSectionDescription}
            </p>
            <p className={`text-lg ${language === "ar" ? "text-right" : ""} text-gray-600`}>{t.mission}</p>
          </div>
          <div className="flex justify-center">
            <img
              src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=60"
              alt="E-commerce"
              className="rounded-lg shadow-lg w-full h-auto"
            />
          </div>
        </div>

        <div className="mt-12 flex justify-center">
          <img
            src={Image}
            alt="Marketing"
            className="rounded-lg shadow-lg w-full md:w-1/2 h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
