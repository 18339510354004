import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, Circle } from 'react-leaflet';
import L from 'leaflet';

const LocationMarker = ({ position, setPosition }) => {
  useMapEvents({
    click(e) {
      setPosition(e.latlng);
    },
  });

  return position === null ? null : (
    <Marker position={position} icon={L.icon({ iconUrl: '/marker-icon.png' })}></Marker>
  );
};

const MapWithRadius = ({ radius, position }) => {
  return position === null ? null : (
    <Circle
      center={position}
      radius={radius}
      pathOptions={{ fillColor: 'blue' }}
    />
  );
};

const LocationSelector = () => {
  const [position, setPosition] = useState(null);
  const [radius, setRadius] = useState(1000); // Radius in meters

  return (
    <div className="p-4 max-w-lg mx-auto space-y-6">
      <div>
        <h2 className="text-lg font-medium mb-2">Select store Location</h2>
        <button
          className="w-full text-left bg-white border border-gray-300 p-3 rounded-md"
          onClick={() => document.getElementById('map-container').scrollIntoView({ behavior: 'smooth' })}
        >
          Select Location &rarr;
        </button>
        {position && (
          <div className="mt-2 text-gray-500">
            Address: {position.lat.toFixed(6)}, {position.lng.toFixed(6)}
          </div>
        )}
      </div>

      <div>
        <h2 className="text-lg font-medium mb-2">Select Delivery Radius</h2>
        <button
          className="w-full text-left bg-white border border-gray-300 p-3 rounded-md"
          onClick={() => document.getElementById('map-container').scrollIntoView({ behavior: 'smooth' })}
        >
          Select Radius &rarr;
        </button>
        <div className="mt-2 text-gray-500">Radius: {radius / 1000} km</div>
      </div>

      <div id="map-container" className="mt-6">
        <MapContainer
          center={position || [29.3759, 47.9774]}
          zoom={13}
          style={{ height: '400px', width: '100%' }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <LocationMarker position={position} setPosition={setPosition} />
          <MapWithRadius radius={radius} position={position} />
        </MapContainer>
        {position && (
          <input
            type="range"
            min="100"
            max="5000"
            value={radius}
            onChange={(e) => setRadius(e.target.value)}
            className="mt-4 w-full"
          />
        )}
      </div>

      <button className="bg-black text-white p-3 rounded-md w-full mt-4">
        Save and finish
      </button>
      <button className="bg-white text-black border border-black p-3 rounded-md w-full mt-2">
        Back
      </button>
    </div>
  );
};

export default LocationSelector;
