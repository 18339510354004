import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">Last updated: August 08, 2024</p>
      <p className="mb-4">
        This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
      </p>
      <p className="mb-4">
        We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Free Privacy Policy Generator.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Interpretation and Definitions</h2>
      <h3 className="text-xl font-semibold mb-2">Interpretation</h3>
      <p className="mb-4">
        The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
      </p>

      <h3 className="text-xl font-semibold mb-2">Definitions</h3>
      <p className="mb-4">
        For the purposes of this Privacy Policy:
      </p>
      <ul className="list-disc pl-5 mb-4">
        <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
        <li><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party...</li>
        <li><strong>Application</strong> refers to Markaz, the software program provided by the Company.</li>
        <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Markaz.</li>
        <li><strong>Country</strong> refers to: Kuwait</li>
        <li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
        <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>
        <li><strong>Service</strong> refers to the Application.</li>
        <li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company...</li>
        <li><strong>Usage Data</strong> refers to data collected automatically...</li>
        <li><strong>You</strong> means the individual accessing or using the Service...</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">Collecting and Using Your Personal Data</h2>
      <h3 className="text-xl font-semibold mb-2">Types of Data Collected</h3>

      <h4 className="text-lg font-semibold mb-2">Personal Data</h4>
      <p className="mb-4">
        While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You...
      </p>
      
      <h4 className="text-lg font-semibold mb-2">Usage Data</h4>
      <p className="mb-4">
        Usage Data is collected automatically when using the Service...
      </p>

      <h3 className="text-xl font-semibold mb-2">Information Collected while Using the Application</h3>
      <p className="mb-4">
        While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission: Information regarding your location...
      </p>

      <h3 className="text-xl font-semibold mb-2">Use of Your Personal Data</h3>
      <p className="mb-4">
        The Company may use Personal Data for the following purposes...
      </p>

      <h3 className="text-xl font-semibold mb-2">Retention of Your Personal Data</h3>
      <p className="mb-4">
        The Company will retain Your Personal Data only for as long as is necessary...
      </p>

      <h3 className="text-xl font-semibold mb-2">Transfer of Your Personal Data</h3>
      <p className="mb-4">
        Your information, including Personal Data, is processed at the Company's operating offices...
      </p>

      <h3 className="text-xl font-semibold mb-2">Delete Your Personal Data</h3>
      <p className="mb-4">
        You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You...
      </p>

      <h3 className="text-xl font-semibold mb-2">Disclosure of Your Personal Data</h3>
      <p className="mb-4">
        The Company may disclose Your Personal Data in the good faith belief that such action is necessary...
      </p>

      <h3 className="text-xl font-semibold mb-2">Security of Your Personal Data</h3>
      <p className="mb-4">
        The security of Your Personal Data is important to Us...
      </p>

      <h3 className="text-xl font-semibold mb-2">Children's Privacy</h3>
      <p className="mb-4">
        Our Service does not address anyone under the age of 13...
      </p>

      <h3 className="text-xl font-semibold mb-2">Links to Other Websites</h3>
      <p className="mb-4">
        Our Service may contain links to other websites that are not operated by Us...
      </p>

      <h3 className="text-xl font-semibold mb-2">Changes to this Privacy Policy</h3>
      <p className="mb-4">
        We may update Our Privacy Policy from time to time...
      </p>

      <h3 className="text-xl font-semibold mb-2">Contact Us</h3>
      <p className="mb-4">
        If you have any questions about this Privacy Policy, You can contact us:
      </p>
      <ul className="list-disc pl-5 mb-4">
        <li>By email: support@mawskw.com</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
